import React, { useState } from "react";

import { Link, useLocation } from "react-router-dom"

import { useTranslation } from "react-i18next";

import logo from "../img/avad.svg"

function MobileMenu({ toggleMenu, isOpen }) {

    const location = useLocation();

    const { t } = useTranslation();

    return (
        <div>
            <div className="relative z-[900] w-[30px] h-[15px] flex flex-col justify-between xl:hidden" onClick={toggleMenu}>
                <div className={`w-full h-[1px] transition duration-300 transform ${isOpen ? 'rotate-45 translate-y-[5px] bg-[#000] w-[35px]' : 'bg-[#000]'}`}></div>
                <div className={`w-full h-[1px]  transition duration-300 transform ${isOpen ? '-rotate-45 -translate-y-[9px] bg-[#000] w-[35px]' : 'bg-[#000]'}`}></div>
            </div>
            <div className={`fixed top-0 right-0 bottom-0 w-full h-[200px] md:w-1/2 bg-white z-50 transform transition-all duration-500 ${isOpen ? 'translate-y-[55px]' : 'translate-y-[-300px]'}`}>
                <div className="flex flex-col items-end gap-[15px] mt-[40px] mx-[20px]">
                    <Link to="/services"><p className={`text-[24px] neutral ${location.pathname === '/services' ? 'text-[#D14507]' : 'text-[#D14507'}`} onClick={toggleMenu}>{t('header.1')}</p></Link>
                    <Link to="/about"><p className={`text-[24px] neutral ${location.pathname === '/about' ? 'text-[#D14507]' : 'text-[#D14507'}`} onClick={toggleMenu}>{t('header.2')}</p></Link>
                    <Link to="/cars"><p className={`text-[24px] neutral ${location.pathname === '/cars' ? 'text-[#D14507]' : 'text-[#D14507'}`} onClick={toggleMenu}>{t('header.3')}</p></Link>
                </div>
            </div>
        </div>
    );
}

export default MobileMenu;