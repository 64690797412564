import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from '../img/avad.svg';
import Fade from 'react-reveal/Fade';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import '../components/header.css';
import { slide as Menu } from 'react-burger-menu';
import i18n from "i18next";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import MobileMenu from "./MobileMenu";

function Header({ currentLang, storedLang, setCurrentLang }) {
  const { t } = useTranslation();
  const location = useLocation();

  const languages = [
    { value: 'en', label: 'EN' },
    { value: 'ar', label: 'AR' },
    { value: 'tr', label: 'TR' },
  ];

  useEffect(() => {
    if (storedLang && storedLang !== currentLang) {
      setCurrentLang(storedLang);
      i18n.changeLanguage(storedLang);
    }
    document.body.setAttribute('dir', storedLang === 'ar' ? 'rtl' : 'ltr');
  }, [storedLang]);

  const handleChangeLang = (selectedLang) => {
    setCurrentLang(selectedLang.value);
    i18n.changeLanguage(selectedLang.value);
    Cookies.set("i18next", selectedLang.value);
    closeMenu();
  };

  const [menuOpen, setMenuOpen] = useState(false);

  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }

    return () => {
      document.body.classList.remove('menu-open');
    };
  }, [menuOpen]);

  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const [toggleServices, settoggleServices] = useState(false);

  useEffect(() => {
    settoggleServices(false);
  }, [location]);

  const getActiveStyle = (path) => {
    return location.pathname === path ? { color: '#D14507' } : {};
  };

  return (
    <>
      <header>
        <div className='h-[80px] max-w-[1280px] mx-auto hidden md:block'>
          <div className="flex gap-[40px] items-end mt-[15px] mx-[15px]">
            <Link to="/">
              <img src={logo} alt="" />
            </Link>
            <Dropdown
              options={languages}
              onChange={handleChangeLang}
              placeholder="EN"
              value={languages.find(lang => lang.value === currentLang)}
            />
            <div>
              <div onClick={() => settoggleServices(!toggleServices)} className="flex items-center cursor-pointer gap-[5px]">
                <p className="font-normal text-[16px] inter textcolor">{t('header.4')}</p>
                <svg className={`transition-all ${toggleServices ? '' : 'rotate-180'}`} xmlns="http://www.w3.org/2000/svg" width="13" height="7" viewBox="0 0 13 7" fill="none">
                  <path d="M12 6L6.33333 0.999999L1 6" stroke="#000000B2" strokeWidth="1.2"/>
                </svg>
              </div>
              {toggleServices &&
              <Fade>
                <div className="flex flex-col absolute">
                  <Link
                    to="/services"
                    style={getActiveStyle('/services')}
                    className="font-normal text-[16px] inter textcolor"
                  >
                    {t('header.1')}
                  </Link>
                  <Link
                    to="/cars"
                    style={getActiveStyle('/cars')}
                    className="font-normal text-[16px] inter textcolor"
                  >
                    {t('header.3')}
                  </Link>
                </div>
              </Fade>}
            </div>
            <Link
              to="/about"
              style={getActiveStyle('/about')}
              className="font-normal text-[16px] inter textcolor"
            >
              {t('header.2')}
            </Link>
          </div>
        </div>
        <div className="fixed top-0 z-50 bg-white w-full flex justify-between h-[60px] md:hidden">
          <div className="flex items-center mx-[20px]">
            <Link to="/" onClick={() => setIsOpen(false)}>
              <img src={logo} alt="" className="w-[100px] z-[500] relative"/>
            </Link>
          </div>
          <div className="flex items-center mx-[20px]">
            <Dropdown
              options={languages}
              placeholder="EN"
              onChange={handleChangeLang}
              value={languages.find(lang => lang.value === currentLang)}
              className="ml-auto mx-[20px]"
            />
            <MobileMenu toggleMenu={toggleMenu} isOpen={isOpen}/>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
