import React from 'react';

import main from '../../img/About/main.png'
import mainadapt from '../../img/About/mainadap.png'
import Fade from 'react-reveal/Fade'

import { useTranslation } from "react-i18next";

function Main() {

  const { t, i18n } = useTranslation();

  const isRTL = i18n.language === 'ar'; 

  return (
    <>
        <div className='max-w-[1280px] mx-auto mt-[80px] xl:h-[680px] relative'>
            <div className='xl:absolute top-0 left-0 xl:h-full flex flex-col mx-[20px] xl:mx-0 z-10 relative'>
                <Fade bottom delay={200}><h1 className='neutral text-[32px] xl:text-[54px] xl:w-[827px] mt-[20px]'>{t('about.1')}</h1></Fade>
                <Fade bottom delay={500}><p className='inter text-[16px] xl:text-[24px] mt-[12px] leading-[1.7] xl:mt-[10px] xl:max-w-[722px]'>{t('about.2')}</p></Fade>
                <div className={isRTL ? 'xl:mr-[250px]' : ''}>
                  <div className='mt-[60px]'>
                      <div className='bg-black h-[1px] w-[178px] xl:w-[280px]'></div>
                      <div className='flex items-center mt-[13px] gap-[10px]'>
                          <div className='w-[4px] h-[4px] bg-black'></div>
                          <h1 className='neutral text-[16px] xl:text-[24px]'>{t('about.3')}</h1>
                      </div>
                  </div>
                  <Fade bottom delay={800}><p className='inter textcolor text-[12px] font-[300] xl:text-[16px] xl:w-[497px] mt-[20px] xl:mt-[13px]'>{t('about.4')}</p></Fade>
                </div>
            </div>
            <Fade bottom delay={500}><img src={main} alt="" className='w-full xl:block hidden'/></Fade>
            <Fade bottom delay={500}><img src={mainadapt} alt="" className='xl:hidden h-[313px] block mt-[60px] w-[100%] object-cover'/></Fade>
        </div>
    </>
  );
}

export default Main;
