import React from 'react';

import yoursucess from '../../img/yoursucess.jpg'

import Fade from 'react-reveal/Fade'

import { useTranslation } from "react-i18next";

function YourSucess({ currentLang }) {

  const { t } = useTranslation();

  return (
    <>
        <div className='mt-[100px] xl:mt-[160px] max-w-[1280px] mx-auto relative overflow-hidden'>
            <img src={yoursucess} alt="" className='object-cover h-[518px] xl:h-auto'/>
            <Fade top delay={200}><div className={`absolute ${currentLang === 'ar' ? 'right-0' : 'left-0'} top-[40px] xl:top-[0px] w-full mx-[20px] xl:mx-0 xl:w-[600px] h-auto flex bg-white`}>
                <div className='pb-[34px] mx-[20px] xl:mx-0 mt-[40px] xl:mt-0'>
                    <h1 className='inter text-[16px] xl:text-[24px]'>{t('home.44')}</h1>
                    <h1 className='neutral text-[24px] xl:text-[36px] mt-[13px] max-w-[553px]'>{t('home.45')}</h1>
                </div>
            </div></Fade>
        </div>
    </>
  );
}

export default YourSucess;
