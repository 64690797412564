import React, { useEffect } from 'react';

import Main from '../components/About/Main'
import AboutUSUS from '../components/About/AboutUSUS';
import OurValues from '../components/Home/OurValues';
import OurPeople from '../components/About/OurPeople';
import FeedBack from '../components/Home/FeedBack';

function About({currentLang}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Main/>
      <AboutUSUS/>
      <OurValues/>
      <OurPeople/>
      <FeedBack currentLang={currentLang}/>
    </>
  );
}

export default About;
