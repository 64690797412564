import React from 'react';

import main from '../../img/Services/main.png'
import mainadapt from '../../img/Services/mainpng.png'
import Fade from 'react-reveal/Fade'

import { useTranslation } from "react-i18next";

function Main() {
  const { t, i18n } = useTranslation();

  const isRTL = i18n.language === 'ar'; 

  return (
    <>
        <div className='max-w-[1280px] mx-auto mt-[80px] xl:h-[680px] relative'>
            <div className='xl:absolute top-0 left-0 xl:h-full flex flex-col mx-[20px] xl:mx-0 z-10 relative'>
                <Fade bottom delay={200}><p className='inter text-[16px] xl:text-[24px] xl:mt-[17px]'>{t('services.1')}</p></Fade>
                <div>
                  <Fade bottom delay={500}><h1 className={`neutral text-[32px] xl:text-[54px] xl:w-[827px] mt-[20px]`}>{t('services.2')}</h1></Fade>
                  <Fade bottom delay={800}><p className='inter textcolor text-[12px] xl:text-[16px] xl:w-[497px] mt-[20px] xl:mt-[50px]'>{t('services.3')}</p></Fade>
                </div>
            </div>
            <Fade bottom delay={500}><img src={main} alt="" className='w-full xl:block hidden'/></Fade>
            <Fade bottom delay={500}><img src={mainadapt} alt="" className='xl:hidden h-[313px] block mt-[40px] w-[100%] object-cover'/></Fade>
        </div>
    </>
  );
}

export default Main;
