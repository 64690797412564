import React, { useEffect } from 'react';

import Main from '../components/Services/Main'
import WhatWeOffer from '../components/Services/WhatWeOffer';
import WhyPartner from '../components/Services/WhyPartner';
import OurProducts from '../components/Services/OurProducts';
import Global from '../components/Services/Global';
import Advantages from '../components/Services/Advantages';

function Services( { currentLang } ) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Main/>
      <WhatWeOffer currentLang={currentLang}/>
      <WhyPartner/>
      <OurProducts/>
      <Global currentLang={currentLang}/>
      <Advantages currentLang={currentLang}/>
    </>
  );
}

export default Services;
