import React from 'react';

import { useState, useEffect } from "react";

import why1 from '../../img/why1.png'
import why2 from '../../img/why2.png'
import why3 from '../../img/why3.png'

import button from '../../img/button.svg'

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from 'swiper/modules';

import 'swiper/css';

import { useTranslation } from "react-i18next";

function WhyChoose( { currentLang } ) {

  const [swiper, setSwiper] = useState({});

  const { t } = useTranslation();

  const [reloadKey, setReloadKey] = useState(0);

    useEffect(() => {
        setReloadKey(prev => prev + 1); // Обновляем ключ каждый раз при смене языка
    }, [currentLang]);

  return (
    <>
        <div className='max-w-[1280px] mx-auto mt-[100px] xl:mt-[160px]' key={reloadKey}>
            <div className='mx-[20px] xl:mx-0'>
                <div className='bg-black h-[1px] w-[178px] xl:w-[280px]'></div>
                <div className='flex items-center mt-[13px] gap-[10px]'>
                    <div className='w-[4px] h-[4px] bg-black'></div>
                    <h1 className='neutral text-[16px] xl:text-[24px]'>{t('home.37')}</h1>
                </div>
            </div>
            <div className='mt-[60px] gap-[50px] hidden xl:flex'>
                <div class='relative w-[393px] h-[339px]'>
                    <img src={why3} alt="" class='absolute w-full h-full'/>
                    <div class='relative flex-col'>
                        <p class='text-white inter text-[20px] mt-[20px] mx-[40px] font-medium'>01</p>
                        <p class='text-white inter text-[20px] mt-[20px] mx-[40px] font-medium w-[250px] mt-[210px]'>{t('home.38')}</p>
                    </div>
                    <div class="absolute top-0 left-0 w-full h-full bg-white opacity-0 hover:opacity-100 transition-opacity duration-500 border border-[#00000033]">
                        <p class='text-black inter text-[20px] mt-[20px] mx-[40px] font-light'>01</p>
                        <p class='text-black inter text-[20px] mt-[20px] mx-[40px] font-medium w-[250px] mt-[50px]'>{t('home.38')}</p>
                        <p className='textcolor text-[16px] font-light w-[300px] mx-[40px] mt-[12px]'>{t('home.39')}</p>
                    </div>
                </div>
                <div class='relative w-[393px] h-[339px]'>
                    <img src={why2} alt="" class='absolute w-full h-full'/>
                    <div class='relative flex-col'>
                        <p class='text-white inter text-[20px] mt-[20px] mx-[40px] font-medium'>02</p>
                        <p class='text-white inter text-[20px] mt-[20px] mx-[40px] font-medium w-[250px] mt-[210px]'>{t('home.40')}</p>
                    </div>
                    <div class="absolute top-0 left-0 w-full h-full bg-white opacity-0 hover:opacity-100 transition-opacity duration-500 border border-[#00000033]">
                        <p class='text-black inter text-[20px] mt-[20px] mx-[40px] font-light'>02</p>
                        <p class='text-black inter text-[20px] mt-[20px] mx-[40px] font-medium w-[250px] mt-[50px]'>{t('home.40')}</p>
                        <p className='textcolor text-[16px] font-light w-[300px] mx-[40px] mt-[12px]'>{t('home.41')}</p>
                    </div>
                </div>
                <div class='relative w-[393px] h-[339px]'>
                    <img src={why1} alt="" class='absolute w-full h-full'/>
                    <div class='relative flex-col'>
                        <p class='text-white inter text-[20px] mt-[20px] mx-[40px] font-medium'>03</p>
                        <p class='text-white inter text-[20px] mt-[20px] mx-[40px] font-medium w-[250px] mt-[210px]'>{t('home.42')}</p>
                    </div>
                    <div class="absolute top-0 left-0 w-full h-full bg-white opacity-0 hover:opacity-100 transition-opacity duration-500 border border-[#00000033]">
                        <p class='text-black inter text-[20px] mt-[20px] mx-[40px] font-light'>03</p>
                        <p class='text-black inter text-[20px] mt-[20px] mx-[40px] font-medium w-[250px] mt-[50px]'>{t('home.42')}</p>
                        <p className='textcolor text-[16px] font-light w-[300px] mx-[40px] mt-[12px]'>{t('home.43')}</p>
                    </div>
                </div>
            </div>
            <div className='mt-[40px] block xl:hidden w-full'>
                <Swiper
                    modules={[Navigation, Autoplay]}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{ delay: 3000, disableOnInteraction: false }}
                    className='mt-[40px]'
                    onInit={(e) => {
                        setSwiper(e);
                    }}
                    breakpoints={{
                        768: { // Указываем ширину экрана (в пикселях)
                            slidesPerView: 2, // Количество слайдов на планшетах
                        },
                    }}
                >
                    <SwiperSlide>
                        <div class="mx-[20px] h-[251px] bg-white border border-[#00000033]">
                            <p class='text-black inter text-[16px] mt-[20px] mx-[20px] font-light'>01</p>
                            <p class='text-black inter text-[16px] mt-[20px] mx-[20px] font-medium'>{t('home.38')}</p>
                            <p className='textcolor text-[12px] font-light mx-[20px] mt-[12px]'>{t('home.39')}</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div class="mx-[20px] h-[251px] bg-white border border-[#00000033]">
                            <p class='text-black inter text-[16px] mt-[20px] mx-[20px] font-light'>02</p>
                            <p class='text-black inter text-[16px] mt-[20px] mx-[20px] font-medium'>{t('home.40')}</p>
                            <p className='textcolor text-[12px] font-light mx-[20px] mt-[12px]'>{t('home.41')}</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div class="mx-[20px] h-[251px] bg-white border border-[#00000033]">
                            <p class='text-black inter text-[16px] mt-[20px] mx-[20px] font-light'>03</p>
                            <p class='text-black inter text-[16px] mt-[20px] mx-[20px] font-medium'>{t('home.42')}</p>
                            <p className='textcolor text-[12px] font-light mx-[20px] mt-[12px]'>{t('home.43')}</p>
                        </div>
                    </SwiperSlide>
                </Swiper>
                <div className={`flex justify-between ${currentLang === 'ar' ? 'flex-row-reverse' : 'flex-row'} mx-[20px] mt-[20px]`}>
                    <img
                    onClick={() => swiper.slidePrev()}
                    src={button}
                    className='w-[30px] h-[30px] rotate-180'
                    />
                    <img
                    onClick={() => swiper.slideNext()}
                    src={button}
                    className='w-[30px] h-[30px]'
                    />
                </div>
            </div>
        </div>
    </>
  );
}

export default WhyChoose;
