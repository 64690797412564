import React from 'react';

import Fade from 'react-reveal/Fade'

import img from '../../img/Services/img.jpg'

import adap from '../../img/reachadap.png'

import { useTranslation } from "react-i18next";

function Global({currentLang}) {

  const { t } = useTranslation();

  return (
    <>
        <div className='relative mt-[100px] xl:mt-[160px] mx-[20px] max-w-[1280px] xl:mx-auto'>
            <div className={`xl:absolute xl:bg-white bottom-0 ${currentLang === 'ar' ? 'right-0' : 'left-0'} xl:w-[805px] xl:h-[338px]`}>
                <div className='xl:mt-[80px]'>
                    <div className='bg-black h-[1px] w-[178px] xl:w-[280px]'></div>
                    <div className='flex items-center mt-[13px] gap-[10px]'>
                        <div className='w-[4px] h-[4px] bg-black'></div>
                        <h1 className='neutral text-[16px] xl:text-[24px]'>{t('services.38')}</h1>
                    </div>
                </div>
                <p className='xl:max-w-[600px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2] mt-[20px] xl:mt-[40px]'>{t('services.39')}</p>
                <p className='xl:max-w-[600px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2] mt-[20px]'>{t('services.40')}</p>
            </div>
            <img src={img} alt="" className='mt-[20px] xl:mt-0 hidden sm:block'/>
            <img src={adap} alt="" className='block sm:hidden w-full h-full mt-[20px]'/>
        </div>
    </>
  );
}

export default Global;
