import React, { useEffect } from 'react';

import Main from '../components/AvadCars/Main'
import Experience from '../components/AvadCars/Experience';
import WeOffer from '../components/AvadCars/WeOffer';

function AvadCars({currentLang}) {
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  return (
    <>
      <Main currentLang={currentLang}/>
      <Experience currentLang={currentLang}/>
      <WeOffer currentLang={currentLang}/>
    </>
  );
}

export default AvadCars;
